html {
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	box-sizing: border-box;
}
body {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	line-height: 1.4;
	color: #333;
	margin: 0;
	padding: 0;
	background: #f2f5ff;
	height: 100vh;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position-x: right;
	background-position-y: bottom;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
a {
	margin: 0;
	padding: 0;
	line-height: 1;
}
h1,
h2,
h3,
h4 {
	font-family: 'Montserrat', sans-serif;
	padding-bottom: 20px;
}
h5,
h6 {
	font-family: 'Montserrat', sans-serif;
	padding-top: 3px;
	padding-bottom: 6px;
}
ul {
	padding-left: 20px;
}
li {
	padding-top: 10px;
}
a,
.link {
	color: #1c75bc;
	text-decoration: none;
	cursor: pointer;
	outline: none;
}
a:hover,
.link:hover {
	transition: all 0.15s ease-in-out;
	color: #1c75bc;
	text-decoration: underline;
}
a:active,
a:focus {
	opacity: 1;
	transition: all 0.15s ease-in-out;
}
a:focus {
	outline: thin dotted;
	outline-offset: 3px;
}
img {
	vertical-align: top;
	max-width: 100%;
	height: auto;
	border: none;
}
iframe {
	max-width: 100%;
	border: none;
}
input,
select,
textarea,
button {
	font-size: 14px;
	color: #000;
	padding: 4px;
	overflow: auto;
}
textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'] {
	border: 1px solid #dddddd;
	background: #fff;
	padding: 0 13px;
	box-shadow: none;
	height: 49px;
	max-width: 100%;
	border-radius: 0px;
	width: 100%;
}
input[type='search'] {
	-webkit-appearance: none;
}
textarea {
	line-height: normal;
	padding: 9px;
	width: 100%;
}
textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus {
	border-color: #0eb1fe;
	background: #f9f9f9;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
input[type='image'],
input[type='submit'],
input[type='button'],
button {
	background: #f1592a;
	border: medium none;
	border-radius: 0px;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	padding: 0px 23px;
	text-transform: uppercase;
	height: 40px;
	transition: all 0.1s ease-in-out 0s;
	font-weight: bold;
	-moz-appearance: none;
	-webkit-appearance: none;
	max-width: 100%;
}
input[type='image']:hover,
input[type='submit']:hover,
input[type='button']:hover,
button:hover {
	background-color: #555;
}
input[type='image']:active,
input[type='submit']:active,
input[type='button']:active,
button:active {
	box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.2);
}
#transactions .colTx {
	padding-left: 0;
	width: 25%;
}
#transactions .colDate {
	width: 30%;
	text-align: right;
	padding-left: 0;
}
#transactions .colDash {
	width: 16%;
	text-align: right;
	padding-right: 10px;
}
#transactions .colFiat {
	width: 12%;
	text-align: right;
	padding-right: 10px;
}
#transactions .colConfirmations {
	display: none; /*not really needed, everything is always confirmed*/
	width: 17%;
	padding-right: 0;
}
#transactions table tr td {
	padding: 4px 8px;
	font-size: 12px;
}
#transactions table tr th {
	padding: 0;
	font-weight: 500;
}
#transactions table tr:nth-child(even) {
	background-color: #e5e9ff;
}
#transactions td {
	font-size: 15px;
}
#transactions tr.sent .colDate,
#transactions tr.sent .colDash,
#transactions tr.sent .colFiat {
	color: #1c2e9e;
}
#transactions tr.received .colDate,
#transactions tr.received .colDash,
#transactions tr.received .colFiat {
	color: #f1592a;
}
label {
	margin-bottom: 0;
}
@media screen and (max-width: 767px) {
	h1 {
		font-size: 1.4rem;
	}
	h2 {
		font-size: 1.3rem;
	}
	h3 {
		font-size: 1.2rem;
	}
	input,
	select,
	textarea,
	button {
		padding: 1px;
	}
	.col {
		flex-basis: auto;
	}
	.row {
		margin-right: 0;
	}
	input[type='image'],
	input[type='submit'],
	input[type='button'],
	button {
		padding: 0px 18px;
	}
}
.Dropdown-control {
	width: 82%;
}
.Dropdown-placeholder {
	height: 14px;
	font-size: 12px;
}
.Dropdown-menu {
	font-size: 12px;
}
